export const SlinkyUrlParams = {
  id: "id",
  code: "code",
  spotifyCode: "spotifyCode",
  error: "error",
  trackingLink: "tl",
};

export const SlinkyLocalStorageNames = {
  spotifyPresave: "spotifyPresave",
  permissions: "euconsent-v2",
  preferences: "slinkyPreferences",
  urlExtension: "urlExtension",
  imageUrl: "imageUrl",
  correlationId: "correlationId",
};
