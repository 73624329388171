import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { unknownImage } from "../../constants/urls";
import { ImageWrapperProps } from "./ImageWrapperProps";

const ImageWrapper = ({
  src,
  fallbackSrc,
  width,
  height,
  fullWidth,
  alt,
  autoHeight,
  fit,
  className,
  style,
  hideOnError,
  children,
  wrapperSx,
}: ImageWrapperProps) => {
  const [imageSrc, setImageSrc] = useState(src);

  const useFallBackImg = () => {
    setImageSrc(fallbackSrc || unknownImage);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const appliedHeight = autoHeight ? "auto" : height || width;
  const appliedWidth = fullWidth ? "100%" : width;

  const imageElementHeight = children ? "100%" : appliedHeight;
  const imageElementWidth = children ? "100%" : appliedWidth;

  const imageElement = (
    <img
      src={imageSrc}
      onError={useFallBackImg}
      alt={alt || ""}
      height={appliedHeight}
      width={appliedWidth}
      style={{
        ...style,
        objectFit: fit || style?.objectFit,
        height: imageElementHeight,
        width: imageElementWidth,
      }}
      className={className}
    />
  );

  return hideOnError && imageSrc === unknownImage ? (
    <></>
  ) : children ? (
    <Stack
      sx={{
        ...wrapperSx,
        position: "relative",
      }}
      alignItems={"center"}
      justifyContent={"center"}
      height={appliedHeight}
      width={appliedWidth}
      maxWidth="100%"
    >
      {imageElement}
      {children && (
        <Box
          sx={{
            position: "absolute",
            right: -5,
            bottom: -5,
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      )}
    </Stack>
  ) : (
    imageElement
  );
};

export default ImageWrapper;
