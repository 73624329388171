import { ImageWrapper } from "@anthology/shared/src/components";
import {
  SlinkyLocalStorageNames,
  SlinkyUrlParams,
} from "@anthology/shared/src/components/slinky";
import { slinkyIconUrl } from "@anthology/shared/src/constants/urls";
import { Stack } from "@mui/material";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

function HomePage() {
  const searchParams = useSearchParams();
  const spotifyCode = searchParams.get(SlinkyUrlParams.code);
  const error = searchParams.get(SlinkyUrlParams.error);
  const router = useRouter();

  const [lastSlinkyImage, setLastSlinkyImage] = useState("");

  useEffect(() => {
    const urlExtension = localStorage.getItem(
      SlinkyLocalStorageNames.urlExtension
    );
    setTimeout(() => {
      if (urlExtension && (spotifyCode || error)) {
        router.push(
          `/${urlExtension}${spotifyCode ? `?${SlinkyUrlParams.spotifyCode}=${spotifyCode}` : ""}`,
          { scroll: false }
        );
      }
    });

    const storedSlinkyImage = localStorage.getItem(
      SlinkyLocalStorageNames.imageUrl
    );
    if (storedSlinkyImage) {
      setLastSlinkyImage(storedSlinkyImage);
    }
  }, [error, router, spotifyCode]);

  return (
    <Stack className="centreContent">
      <ImageWrapper
        src={lastSlinkyImage || slinkyIconUrl}
        width={lastSlinkyImage ? 200 : 50}
        autoHeight
        style={{ filter: lastSlinkyImage ? "" : "invert(100%)" }}
      />
    </Stack>
  );
}

export default HomePage;
